import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import '@fontsource/rubik';
import { ReactNotifications } from 'react-notifications-component';
import { RouterProvider } from 'react-router-dom';

import { queryClient } from 'config';
import { AuthProvider } from 'context/authContext';
import router from 'routes/router';
import { GlobalStyle } from 'theme';

import 'react-notifications-component/dist/theme.css';

function App() {
  return (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <TooltipProvider delayDuration={200}>
            <ReactNotifications />
            <GlobalStyle />
            <RouterProvider router={router} />
          </TooltipProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
