import { Input, Button } from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { ButtonsContainer, Form } from './CreateFolderForm.styled';

import OrderTooltip from 'pages/PortalsAdmin/components/OrderTooltip/OrderTooltip';
import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';

export interface FormData {
  name: string;
  order: number;
}

interface Props {
  onCancel: () => void;
  onSubmit: (formData: FormData) => void;
}

const CreateFolderForm = ({ onCancel, onSubmit }: Props) => {
  const { formState, register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      name: '',
    },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('name', {
          required: { value: true, message: 'This field is required' },
          maxLength: {
            value: NAME_MAX_LENGTH,
            message: 'Name should be less than 50 characters long',
          },
        })}
        isErrorInitiallyHidden
        error={formState.errors.name?.message}
        label="Folder name"
        placeholder="Enter folder name"
      />
      <Input
        {...register('order')}
        isErrorInitiallyHidden
        label="Order"
        max={999}
        min={1}
        placeholder="(number)"
        tooltipLabel={<OrderTooltip />}
        type="number"
      />

      <ButtonsContainer>
        <Button mode="outlined" size="big" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="big" type="submit">
          Create
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default CreateFolderForm;
