import {
  type TableColumn,
  type TableData,
  useSort,
  useSortedData,
} from '@hummingbird/shared';

import DeleteFolderButton from './DeleteFolderButton/DeleteFolderButton';
import FolderOrder from './FolderOrder/FolderOrder';
import { TooltipContainer } from './useFoldersTable.styled';

import queryClient from 'config/queryClient';
import ActionButton from 'pages/PortalsAdmin/components/ActionButton/ActionButton';
import EditableCell from 'pages/PortalsAdmin/components/EditableCell/EditableCell';
import OrderTooltip from 'pages/PortalsAdmin/components/OrderTooltip/OrderTooltip';
import type { UsePortalsAdminTable } from 'pages/PortalsAdmin/types';
import { ROUTES } from 'routes/constants';
import useUpdateFolder from 'services/api/useUpdateFolder';
import { QUERY_KEYS } from 'services/constants';

const tableColumns: TableColumn[] = [
  { label: 'Name', key: 'name' },
  {
    key: 'order',
    label: (
      <TooltipContainer>
        Order
        <OrderTooltip />
      </TooltipContainer>
    ),
  },
  { label: 'Delete', key: 'delete', isLabelHidden: true },
  {
    key: 'actions',
    label: 'Actions',
    isOverflowVisible: true,
    isLabelHidden: true,
  },
];

type SortableColumns = '';

const useFoldersTable = (
  folders: Folder[],
  site?: Site,
  fund?: Fund,
): UsePortalsAdminTable => {
  const { setSort, sortKey, sortOrder } = useSort<SortableColumns>();
  const { mutate: updateFolder } = useUpdateFolder();

  const handleUpdateFolder = (name: string, folderId: string) => {
    if (!site?.id || !fund?.id) {
      return;
    }

    const payload: UpdateFolderPayload = {
      id: folderId,
      siteId: site.id,
      fundId: fund?.id,
      payload: {
        name,
      },
    };

    updateFolder(payload, {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries([QUERY_KEYS.FOLDERS, fund.id]);
        }, 1000);
      },
    });
  };

  const tableData: TableData[] =
    folders?.map(folder => ({
      id: folder.id,
      name: (
        <EditableCell
          value={folder.name}
          onUpdate={name => {
            handleUpdateFolder(name, folder.id);
          }}
        />
      ),
      order: <FolderOrder folder={folder} fundId={fund?.id!} />,
      delete: <DeleteFolderButton folder={folder} />,
      actions:
        site && fund ? (
          <ActionButton
            to={`${ROUTES.PORTALS_ADMIN}${ROUTES.SITES}/${site.id}${ROUTES.FUNDS}/${fund.id}${ROUTES.FOLDERS}/${folder.id}`}
          />
        ) : undefined,
    })) || [];

  const sortedTableData = useSortedData({
    sortKey,
    sortOrder,
    data: tableData,
  });

  return {
    tableColumns,
    tableData: sortedTableData,
    sortKey,
    sortOrder,
    setSort,
  };
};

export default useFoldersTable;
