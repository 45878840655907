import { Button } from '@hummingbird/shared';

import { AUTH_ENABLED } from '../../constants';

import { Container, LinksContainer } from './NavigationBar.styled';
import NavigationBarItem from './NavigationBarItem/NavigationBarItem';

import { useAuthContext } from 'context/authContext';
import { ROUTES } from 'routes/constants';

const NavigationBar = () => {
  const { setLoggedIn } = useAuthContext();

  const signOut = () => {
    setLoggedIn(false);
  };

  return (
    <Container>
      <LinksContainer>
        <NavigationBarItem
          name="Portals Admin"
          route={`${ROUTES.PORTALS_ADMIN}`}
        />
      </LinksContainer>
      {AUTH_ENABLED && (
        <Button mode="outlined" onClick={signOut}>
          Sign out
        </Button>
      )}
    </Container>
  );
};

export default NavigationBar;
