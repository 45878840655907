import { Button, Input, Modal } from '@hummingbird/shared';
import { type KeyboardEvent, useCallback, useState } from 'react';

import {
  EditableInputContainer,
  ChangeIcon,
  Container,
  ReadOnlyInputContainer,
  ButtonContainer,
} from './EditableOrderCell.styled';

import { addNotification } from 'services/notifications';

interface Props {
  value: number;
  name: 'Site Fund' | 'Folder' | 'Workbook';
  onUpdate: (value: number) => void;
}

const EditableOrderCell = ({ value, name, onUpdate }: Props) => {
  const [isEditing, setEditing] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const resetToInitialValue = useCallback(() => {
    setEditing(false);
    setUpdatedValue(value);
  }, [value]);

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
    resetToInitialValue();
  };

  const handeConfirmationModelOnConfirm = () => {
    setConfirmationModalOpen(false);
    setEditing(false);
    onUpdate(updatedValue);
  };

  const handleOnBlur = useCallback(() => {
    const didValueChanged = updatedValue !== value;

    // Only when order changes modal should be open
    if (didValueChanged) {
      if (updatedValue < 1 || updatedValue > 999) {
        addNotification({
          message: 'Order should be number from 1 to 999',
          title: 'Error',
          type: 'danger',
        });

        resetToInitialValue();

        return;
      }
      setConfirmationModalOpen(true);

      return;
    }

    resetToInitialValue();
  }, [resetToInitialValue, updatedValue, value]);

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const didValueChanged = updatedValue !== value;

      const isEscapeClicked = event.key === 'Escape';
      const isEnterClicked = event.key === 'Enter';

      const shouldReset =
        isEscapeClicked || (isEnterClicked && !didValueChanged);

      if (shouldReset) {
        resetToInitialValue();

        return;
      }

      if (isEnterClicked && didValueChanged) {
        if (updatedValue < 1 || updatedValue > 999) {
          addNotification({
            message: 'Order should be number from 1 to 999',
            title: 'Error',
            type: 'danger',
          });

          resetToInitialValue();

          return;
        }
        setConfirmationModalOpen(true);
      }
    },
    [resetToInitialValue, updatedValue, value],
  );

  const handleIconOnClick = () => {
    setUpdatedValue(updatedValue);
    setEditing(true);
  };

  return (
    <>
      <Container>
        {isEditing ? (
          <EditableInputContainer>
            <Input
              autoFocus
              isErrorInitiallyHidden
              isLabelHidden
              elementSize="small"
              label="order"
              type="number"
              value={updatedValue}
              onBlur={handleOnBlur}
              onChange={e => setUpdatedValue(+e.target.value)}
              onKeyDown={handleOnKeyDown}
            />
          </EditableInputContainer>
        ) : (
          <ReadOnlyInputContainer>
            {value}
            <ChangeIcon onClick={handleIconOnClick} />
          </ReadOnlyInputContainer>
        )}
      </Container>
      <Modal
        isOpen={!!isConfirmationModalOpen}
        title={`Are you sure you want to change ${name} order?`}
        onClose={closeConfirmationModal}>
        Changing the {name} order will affect how the client sees it.
        <ButtonContainer>
          <Button mode="outlined" size="big" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button size="big" onClick={handeConfirmationModelOnConfirm}>
            Yes, I am sure
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default EditableOrderCell;
