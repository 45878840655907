import {
  type SortOrder,
  type TableData,
  Table,
  Button,
  Modal,
  Plus,
} from '@hummingbird/shared';
import { useState } from 'react';

import CreateSiteForm, {
  type CreateSiteFormData,
} from './components/CreateSiteForm/CreateSiteForm';
import SitesSearchInput from './components/SitesSearchInput/SitesSearchInput';
import { Container, Header } from './Sites.styled';
import useSitesTable from './useSitesTable/useSitesTable';

import CenteredSpinner from 'components/CenteredSpinner/CenteredSpinner';
import useCreateSite from 'services/api/useCreateSite';
import useSites from 'services/api/useSites';

const Sites = () => {
  const { data: sites, isLoading: areSitesLoading } = useSites();

  const { mutate: createSite, isLoading: isSiteCreating } = useCreateSite();

  const [searchInput, setSearchInput] = useState('');
  const [isCreateSiteModalOpen, setCreateSiteModalOpen] = useState(false);

  const { tableColumns, tableData, sortKey, sortOrder, setSort } =
    useSitesTable(sites || [], searchInput);

  const closeCreateSiteModal = () => {
    setCreateSiteModalOpen(false);
  };

  const handleCreateSiteFormOnSubmit = (formData: CreateSiteFormData) => {
    const { externalId, domain, visible, name } = formData;

    const payload: CreateSitePayload = {
      external_id: externalId || undefined,
      domain: domain || undefined,
      hidden: !visible,
      name,
    };

    closeCreateSiteModal();
    createSite(payload);
  };

  return (
    <Container>
      <Header>
        <SitesSearchInput value={searchInput} onChange={setSearchInput} />
        <Button icon={Plus} onClick={() => setCreateSiteModalOpen(true)}>
          Create New Site
        </Button>
      </Header>

      {areSitesLoading || isSiteCreating ? (
        <CenteredSpinner />
      ) : (
        <Table
          columns={tableColumns}
          data={tableData}
          sortColumn={sortKey}
          sortDirection={sortOrder}
          onSort={setSort as (key: keyof TableData, order: SortOrder) => void}
        />
      )}

      <Modal
        isOpen={isCreateSiteModalOpen}
        title="Create New Site"
        onClose={closeCreateSiteModal}>
        <CreateSiteForm
          onCancel={closeCreateSiteModal}
          onSubmit={handleCreateSiteFormOnSubmit}
        />
      </Modal>
    </Container>
  );
};

export default Sites;
