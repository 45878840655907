import { Bin, Button, IconButton, Modal } from '@hummingbird/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonContainer } from './DeleteFundButton.styled';

import useDeleteFund from 'services/api/useDeleteFund';

interface Props {
  fund: Fund;
}

const DeleteFundButton = ({ fund }: Props) => {
  const { siteId = '' } = useParams();
  const { mutate: deleteFund } = useDeleteFund(siteId);

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOnConfirm = () => {
    deleteFund({ siteId, fundId: fund.id });
    closeModal();
  };

  return (
    <>
      <IconButton
        icon={Bin}
        label="Delete"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        title={`Are you sure you want to delete "${fund.name}" fund?`}
        onClose={closeModal}>
        Fund and all of its content will be deleted
        <ButtonContainer>
          <Button mode="outlined" size="big" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="big" onClick={handleOnConfirm}>
            Yes, I am sure
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default DeleteFundButton;
