import {
  type TableData,
  type SortOrder,
  Table,
  Modal,
  Plus,
} from '@hummingbird/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import mapUsersResponseToFundViewers from '../../../../../../../utils/mapUsersResponseToFundViewers';
import mapUsersResponseToKiskiExplorers from '../../../../../../../utils/mapUsersResponseToKiskiExplorers';
import mapUsersResponseToKiskiViewers from '../../../../../../../utils/mapUsersResponseToKiskiViewers';
import GrantFundAccessForm, {
  type FundAccessFormData,
} from '../GrantFundAccessForm/GrantFundAccessForm';

import { Card, GrantAccessButton, TableHeader } from './FundUsersCard.styled';
import UsersCardHeader from './UsersCardHeader/UsersCardHeader';

import CenteredSpinner from 'components/CenteredSpinner/CenteredSpinner';
import mapUsersResponseToKiskiPublishers from 'pages/PortalsAdmin/utils/mapUsersResponseToKiskiPublishers';
import useUsersTable from 'pages/PortalsAdmin/utils/useUsersTable';
import useFund from 'services/api/useFund';
import useFundUsers from 'services/api/userProvisioning/useFundUsers';
import useGrantFundAccess from 'services/api/userProvisioning/useGrantFundAccess';
import useRevokeFundAccess from 'services/api/userProvisioning/useRevokeFundAccess';
import useSite from 'services/api/useSite';
import { addNotification } from 'services/notifications';

const FundUsersCard = () => {
  const { siteId = '', fundId = '' } = useParams();

  const { data: site } = useSite(siteId);
  const { data: fund } = useFund(fundId, siteId);

  const { data: users, isLoading: areUsersLoading } = useFundUsers(
    fundId,
    siteId,
  );

  const { mutate: grantAccess } = useGrantFundAccess();
  const { mutate: revokeAccess } = useRevokeFundAccess();

  const [isGrantAccessModalOpen, setGrantAccessModalOpen] = useState(false);

  const fundViewers = mapUsersResponseToFundViewers(users);
  const kiskiViewers = mapUsersResponseToKiskiViewers(users);
  const kiskiExplorers = mapUsersResponseToKiskiExplorers(users);
  const kiskiPublishers = mapUsersResponseToKiskiPublishers(users);

  const handleRevokeAccess = (email: string) => {
    if (!site?.id || !fund?.id) {
      return;
    }

    revokeAccess({ email, siteId: site.id, fundId: fund.id });
  };

  const {
    tableColumns: kiskiViewersTableColumns,
    tableData: kiskiViewersTableData,
    sortKey: kiskiViewersSortKey,
    sortOrder: kiskiViewersSortOrder,
    setSort: kiskiViewersSetSort,
  } = useUsersTable(kiskiViewers, handleRevokeAccess, false);

  const {
    tableColumns: fundTableColumns,
    tableData: fundTableData,
    sortKey: fundSortKey,
    sortOrder: fundSortOrder,
    setSort: fundSetSort,
  } = useUsersTable(fundViewers, handleRevokeAccess, false);

  const {
    tableColumns: kiskiExplorersTableColumns,
    tableData: kiskiExplorersTableData,
    sortKey: kiskiExplorersSortKey,
    sortOrder: kiskiExplorersSortOrder,
    setSort: kiskiExplorersSetSort,
  } = useUsersTable(kiskiExplorers, handleRevokeAccess, false);

  const {
    tableColumns: kiskiPublishersTableColumns,
    tableData: kiskiPublishersTableData,
    sortKey: kiskiPublishersSortKey,
    sortOrder: kiskiPublishersSortOrder,
    setSort: kiskiPublishersSetSort,
  } = useUsersTable(kiskiPublishers, handleRevokeAccess, false);

  const handleGrantAccessFormOnSubmit = (data: FundAccessFormData) => {
    setGrantAccessModalOpen(false);

    if (!site?.id || !fund?.id) {
      return;
    }

    const payload = {
      siteId: site.id,
      fundId: fund.id,
      email: data.email,
    };

    grantAccess(payload, {
      onError: () => {
        addNotification({
          message: '',
          title: 'Error',
          type: 'danger',
        });
      },
    });
  };

  return (
    <>
      <Card aria-label="Fund Users">
        <UsersCardHeader />
        {areUsersLoading ? (
          <CenteredSpinner />
        ) : (
          <>
            <TableHeader>
              Fund Viewers
              <GrantAccessButton
                aria-label="Add Fund Viewer"
                icon={Plus}
                iconPosition="left"
                onClick={() => {
                  setGrantAccessModalOpen(true);
                }}>
                Add Fund Viewer
              </GrantAccessButton>
            </TableHeader>
            {!!fundTableData.length && (
              <Table
                aria-label="Portal Viewers"
                columns={fundTableColumns}
                data={fundTableData}
                sortColumn={fundSortKey}
                sortDirection={fundSortOrder}
                onSort={
                  fundSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
            <TableHeader>Kiski Viewers</TableHeader>
            {!!kiskiViewersTableData.length && (
              <Table
                aria-label="Kiski Viewers"
                columns={kiskiViewersTableColumns}
                data={kiskiViewersTableData}
                sortColumn={kiskiViewersSortKey}
                sortDirection={kiskiViewersSortOrder}
                onSort={
                  kiskiViewersSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
            <TableHeader>Kiski Explorers</TableHeader>
            {!!kiskiExplorersTableData.length && (
              <Table
                aria-label="Kiski Explorers"
                columns={kiskiExplorersTableColumns}
                data={kiskiExplorersTableData}
                sortColumn={kiskiExplorersSortKey}
                sortDirection={kiskiExplorersSortOrder}
                onSort={
                  kiskiExplorersSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
            <TableHeader>Kiski Publishers</TableHeader>
            {!!kiskiPublishersTableData.length && (
              <Table
                aria-label="Kiski Publishers"
                columns={kiskiPublishersTableColumns}
                data={kiskiPublishersTableData}
                sortColumn={kiskiPublishersSortKey}
                sortDirection={kiskiPublishersSortOrder}
                onSort={
                  kiskiPublishersSetSort as (
                    key: keyof TableData,
                    order: SortOrder,
                  ) => void
                }
              />
            )}
          </>
        )}
      </Card>
      <Modal
        isOpen={isGrantAccessModalOpen}
        title={`Grant Fund Access - ${fund?.name}`}
        onClose={() => setGrantAccessModalOpen(false)}>
        <GrantFundAccessForm
          onCancel={() => setGrantAccessModalOpen(false)}
          onSubmit={handleGrantAccessFormOnSubmit}
        />
      </Modal>
    </>
  );
};

export default FundUsersCard;
