import { styled } from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  min-width: 400px;
  text-align: center;
`;
