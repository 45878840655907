import { Input, Button } from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { ButtonsContainer, Form } from './GrantFundAccessForm.styled';

export interface FundAccessFormData {
  email: string;
}

interface Props {
  onCancel: () => void;
  onSubmit: (formData: FundAccessFormData) => void;
}

const GrantFundAccessForm = ({ onCancel, onSubmit }: Props) => {
  const { register, handleSubmit } = useForm<FundAccessFormData>({
    defaultValues: {
      email: '',
    },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('email')}
        isErrorInitiallyHidden
        label="Email"
        placeholder="Enter email"
      />

      <ButtonsContainer>
        <Button mode="outlined" size="big" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="big" type="submit">
          Apply
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default GrantFundAccessForm;
