import {
  type TableColumn,
  type TableData,
  useSort,
  useSortedData,
} from '@hummingbird/shared';

import DeleteFundButton from './DeleteFundButton/DeleteFundButton';
import FundOrder from './FundOrder/FundOrder';
import { TooltipContainer } from './useFundsTable.styled';
import VisibilityButton from './VisibilityButton/VisibilityButton';

import queryClient from 'config/queryClient';
import ActionButton from 'pages/PortalsAdmin/components/ActionButton/ActionButton';
import EditableCell from 'pages/PortalsAdmin/components/EditableCell/EditableCell';
import OrderTooltip from 'pages/PortalsAdmin/components/OrderTooltip/OrderTooltip';
import type { UsePortalsAdminTable } from 'pages/PortalsAdmin/types';
import { ROUTES } from 'routes/constants';
import useUpdateFund from 'services/api/useUpdateFund';
import { QUERY_KEYS } from 'services/constants';

const tableColumns: TableColumn[] = [
  { label: 'Visible on Site', key: 'isVisible' },
  { label: 'Site Fund Name', key: 'fundName', isSortable: true },
  {
    key: 'order',
    label: (
      <TooltipContainer>
        Order
        <OrderTooltip />
      </TooltipContainer>
    ),
  },
  { label: 'Delete', key: 'delete', isLabelHidden: true },
  {
    key: 'actions',
    label: 'Actions',
    isOverflowVisible: true,
    isLabelHidden: true,
  },
];

type SortableColumns = '';

const useFundsTable = (funds: Fund[], site?: Site): UsePortalsAdminTable => {
  const { setSort, sortKey, sortOrder } = useSort<SortableColumns>();
  const { mutate: updateFund } = useUpdateFund();

  const handleUpdateFund = (name: string, fundId: string) => {
    if (!site?.id) {
      return;
    }

    const payload: UpdateFundPayload = {
      id: fundId,
      siteId: site.id,
      payload: {
        name,
      },
    };

    updateFund(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.FUNDS, site.id]);
      },
    });
  };

  const tableData: TableData[] = funds.map(fund => ({
    id: fund.id,
    isVisible: <VisibilityButton fund={fund} siteId={site?.id!} />,
    fundName: (
      <EditableCell
        value={fund.name}
        onUpdate={name => {
          handleUpdateFund(name, fund.id);
        }}
      />
    ),
    order: <FundOrder fund={fund} siteId={site?.id!} />,
    delete: <DeleteFundButton fund={fund} />,
    actions: site ? (
      <ActionButton
        to={`${ROUTES.PORTALS_ADMIN}${ROUTES.SITES}/${site.id}${ROUTES.FUNDS}/${fund.id}`}
      />
    ) : undefined,
  }));

  const sortedTableData = useSortedData({
    sortKey,
    sortOrder,
    data: tableData,
  });

  return {
    tableColumns,
    tableData: sortedTableData,
    sortKey,
    sortOrder,
    setSort,
  };
};

export default useFundsTable;
