import { Pencil } from '@hummingbird/shared';
import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const EditableInputContainer = styled.div`
  display: flex;
  width: fit-content;
  max-width: 150px;
`;

export const ReadOnlyInputContainer = styled.div`
  display: flex;
  gap: 8px;
  width: fit-content;
  min-width: 150px;
`;

export const ChangeIcon = styled(Pencil)`
  height: 18px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-top: 32px;
`;
