import { Button, Checkbox, Input } from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { ButtonsContainer, Form } from './CreateFundForm.styled';

import OrderTooltip from 'pages/PortalsAdmin/components/OrderTooltip/OrderTooltip';
import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';

export interface FormData {
  name: string;
  order: number;
  visible: boolean;
}

interface Props {
  onCancel: () => void;
  onSubmit: (formData: FormData) => void;
}

const CreateFundForm = ({ onCancel, onSubmit }: Props) => {
  const { formState, register, handleSubmit } = useForm<FormData>();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('name', {
          required: { value: true, message: 'This field is required' },
          maxLength: {
            value: NAME_MAX_LENGTH,
            message: 'Name should be less than 50 characters long',
          },
        })}
        isErrorInitiallyHidden
        error={formState.errors.name?.message}
        label="Fund name"
        placeholder="Enter Fund name"
      />
      <Input
        {...register('order')}
        isErrorInitiallyHidden
        label="Order"
        max={999}
        min={1}
        placeholder="(number)"
        tooltipLabel={<OrderTooltip />}
        type="number"
      />
      <Checkbox {...register('visible')} label="Is Visible" />
      <ButtonsContainer>
        <Button mode="outlined" size="big" onClick={onCancel}>
          Cancel
        </Button>
        <Button aria-label="Add Fund" size="big" type="submit">
          Add Fund
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default CreateFundForm;
